@font-face {
  font-family: 'e-Ukraine';
  src: url('../fonts/e-Ukraine-Regular.otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'e-Ukraine';
  src: url('../fonts/e-Ukraine-Bold.otf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'e-Ukraine';
  src: url('../fonts/e-Ukraine-Medium.otf');
  font-weight: 500;
  font-style: normal;
}
