.how-to-inform {
  padding: 80px 0;

  .e-title {
    margin-bottom: 24px;
  }

  &__list {
    font: 18px/1.4 'e-Ukraine', sans-serif;
    color: #333333;
    padding-left: 20px;
    
    @media (max-width: 768px) {
      font-size: 16px;
    }

    li {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
}