.info-icon {
  $info-icon: &;
  display: flex;
  position: absolute;
  z-index: 15;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);

  svg,
  img {
    width: 20px;
    height: auto;
  }

  &__content {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    top: 100%;
    background-color: #000;
    visibility: hidden;
    opacity: 0;
    padding: .5rem .75rem;
    color: #fff;
    font: 10px/1.4 'e-Ukraine', sans-serif;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: .3rem;
    width: 250px;
  }

  &:hover {
    #{$info-icon}__content {
      visibility: visible;
      opacity: 1;
    }
  }
}