.e-header {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  right: 0;
  height: 70px;
  background-color: #EEEEEE;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    height: 60px;
  }

  &__container {
    max-width: 1280px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 8px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__logo {
    display: grid;
    grid-template-columns: 54px 1fr;
    grid-column-gap: 6px;
    align-items: center;
    text-decoration: none;

    @media (max-width: 768px) {
      grid-template-columns: 32px 1fr;
    }

    img {
      border-radius: 5px;
      max-width: 100%;
      height: auto;
    }
  }

  &__logo-text {
    font: 20px/1.2 'e-Ukraine', sans-serif;
    font-weight: 700;
    color: #333333;

    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  &__navigation {
    display: flex;
    align-items: center;
  }

  &__nav {
    margin-right: 90px;

    @media (max-width: 768px) {
      display: none;
    }

    &--mobile {
      display: grid;
      grid-row-gap: 20px;
    }
  }

  &__menu-toggle {
    display: none;
    grid-row-gap: 5px;
    width: 24px;
    cursor: pointer;

    @media (max-width: 768px) {
      display: grid;
    }

    &--line {
      height: 2px;
      width: 100%;
      background-color: #000;
    }
  }
}