.sua {
  text-align: center;
  padding: 70px 0;
  background-color: #3076BE;

  @media (max-width: 768px) {
    padding: 30px 0;
  }

  &__title {
    font: 40px/1.4 'e-Ukraine', sans-serif;
    font-weight: 500;
    color: #fff;

    @media (max-width: 768px) {
      font-size: 32px;
    }
  }
}