.e-message {
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 8px;
  font: 17px/1.4 'e-Ukraine', sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 320px;
  width: 100%;

  &--success {
    background-color: #D4E8DE;
    color: #015C3C;
  }

  &--error {
    background-color: #E8D4D4;
    color: #4F4F4F;
  }

  &__close {
    position: relative;
    width: 24px;
    height: 24px;
    background-color: transparent;
    cursor: pointer;

    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 12px;
      height: 1px;
      background: rgba(39, 39, 39, 0.5);
      top: 50%;
      left: 50%;
    }

    &:before {
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    &:after {
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }
}

.e-messages {
  position: fixed;
  left: 50px;
  bottom: 100px;

  @media (max-width: 576px) {
    bottom: 60px;
  }
}