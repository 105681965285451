.e-table {
  th {
    background-color: #F5F7FA;
    padding: 16px 25px;
    font: 14px/1.2 'e-Ukraine', sans-serif;
    font-weight: 700;
    color: #152935;
  }

  tr {
    &:not(:last-child) {
      border-bottom: 1px solid #DFE3E6;
    }
  }

  td {
    padding: 10px 25px;
    font: 14px/1.2 'e-Ukraine', sans-serif;
    font-weight: 400;
    color: #152935;

    .btn {
      font-family: var(--bs-body-font-family);
      font-size: var(--bs-body-font-size);
    }

    &.activated {
      color: #015C3C;
    }

    &.pending {
      color: #898A8D;
    }
  }
}

.invite-url {
  display: none;
}