.how-it-works {
  padding: 80px 0;
  background-color: #3076BE;
  counter-reset: number;

  @media (max-width: 576px) {
    padding: 40px 0;
  }

  .e-title {
    margin-bottom: 30px;
  }

  .e-text {
    margin-bottom: 40px;
    max-width: 490px;

    @media (max-width: 768px) {
      max-width: 100%;
    }
  }

  &__item {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 416px;
    grid-column-gap: 80px;
    counter-increment: number;

    @media (max-width: 991px) {
      grid-template-columns: 1fr 300px;
    }

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }

    &:not(:last-child) {
      margin-bottom: 40px;
    }
  }

  &__item-title {
    position: relative;
    font: 24px/1.4 'e-Ukraine', sans-serif;
    font-weight: 400;
    margin-bottom: 30px;
    color: #fff;
  }

  &__item-text {
    font: 16px/1.4 'e-Ukraine', sans-serif;
    color: #fff;
    margin-bottom: 22px;

    @media (max-width: 768px) {
      font-size: 14px;
      padding-left: 0;
    }
  }

  &__item-content {
    position: relative;
  }

  &__item-img {
    @media (max-width: 768px) {
      text-align: center;
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }
}