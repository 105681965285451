.contact-us {
  padding: 80px 0;

  .e-title {
    margin-bottom: 14px;
  }

  .e-text {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__email {
    display: inline-block;
    font: 20px/1.2 'e-Ukraine', sans-serif;
    text-decoration: none;
    margin-bottom: 24px;
    color: #266BD2;
  }

  &__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 100px;

    @media (max-width: 991px) {
      grid-column-gap: 50px;
    }

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      text-align: center;
    }
  }

  &__img {
    @media (max-width: 768px) {
      order: -1;
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }
}