.qa {
  $qa: &;
  padding: 80px 0;

  .e-title {
    margin-bottom: 24px;
  }

  &__list {
    counter-reset: number;
  }

  &__item-title {
    display: flex;
    align-items: center;
    font: 18px/1.2 'e-Ukraine', sans-serif;
    font-weight: 400;
    position: relative;
    padding: 30px 20px 20px 0;
    cursor: pointer;

    &::before {
      content: counter(number)". ";
      margin-right: 25px;
    }
  }

  &__item-icon {
    margin: 0 0  0 auto;
  }

  &__item-content {
    font: 16px/1.4 'e-Ukraine', sans-serif;
    font-weight: 400;
    padding: 0 40px ;
    visibility: hidden;
    opacity: 0;
    height: 0;
  }

  &__item {
    counter-increment: number;
    border-bottom: 1px solid #E0E0E0;
    padding-bottom: 10px;

    &--active {
      #{$qa}__item-icon {
        transform: rotate(180deg);
      }

      #{$qa}__item-content {
        visibility: visible;
        opacity: 1;
        height: auto;
      }
    }
  }
}