.e-link {
  font: 14px/1.5 'e-Ukraine', sans-serif;
  color: #717171;
  text-decoration: none;

  &:not(:last-child) {
    margin-right: 24px;
  }

  &--dark {
    color: #000;
  }

  &--white {
    color: #fff;
  }
}