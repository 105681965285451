.btn {
  display: inline-block;
  text-decoration: none;
  text-align: center;
  cursor: pointer;

  &--large {
    padding: 14px 25px;
    border-radius: 8px;
    font: 17px/1.3 'e-Ukraine', sans-serif;
  }

  &--primary {
    background-color: #2A71DB;
    color: #fff;
  }

  &--danger {
    background-color: transparent;
    border: 2px solid #FFA8A8;
    color: #4F4F4F;
  }
}
