.hero {
  position: relative;
  min-height: 100vh;
  background: linear-gradient(103.17deg, rgba(255, 213, 0, 0.3) 0.56%, rgba(42, 113, 219, 0.3) 100%);
  padding-top: 70px;
  display: flex;
  align-items: center;
  overflow: hidden;

  @media (max-width: 576px) {
    padding: 130px 0 290px;
    align-items: flex-start;
  }

  &__container {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 150px;

    @media (max-width: 991px) {
      grid-column-gap: 50px;
    }

    @media (max-width: 576px) {
      grid-template-columns: 1fr;
      text-align: center;
    }
  }

  &__thumb {
    @media (max-width: 576px) {
      position: absolute;
      bottom: -210px;
      left: 50%;
      transform: translateX(-50%);
    }

    img {
      max-width: 100%;
      height: auto;

      @media (max-width: 576px) {
        max-width: initial;
      }
    }
  }

  &__title {
    font: 48px/1.2 'e-Ukraine', sans-serif;
    color: #333333;
    font-weight: 700;
    margin-bottom: 24px;

    @media (max-width: 991px) {
      font-size: 40px;
    }

    @media (max-width: 576px) {
      font-size: 32px;
    }
  }

  &__subtitle {
    font: 16px/1.3 'e-Ukraine', sans-serif;
    font-weight: 400;
    margin-bottom: 32px;
  }

  &__actions {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;

    @media (max-width: 576px) {
      grid-template-columns: 1fr;
      grid-row-gap: 20px;
    }

    a {
      display: block;
    }

    img {
      max-width: 100%;
    }
  }
}
