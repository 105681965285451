.footer {
  padding: 8px 0;
  background-color: #0A0A0A;

  &__logo {
    display: grid;
    align-items: center;
    grid-template-columns: 54px 1fr;
    grid-column-gap: 10px;

    @media (max-width: 768px) {
      order: 1;
    }

    img {
      border-radius: 5px;
    }

    span {
      font: 14px/1.2 'e-Ukraine', sans-serif;
      color: #fff;
    }
  }

  &__container {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  &__nav {
    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      text-align: center;
    }

    .e-link {
      @media (max-width: 768px) {
        margin: 0 0 12px!important;
      }
    }
  }
}