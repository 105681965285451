.mobile-menu {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
  background-color: #fff;
  padding: 20px 30px;
  display: none;
  transform: translateX(-100%);
  transition: all .5s ease-in-out;

  @media (max-width: 768px) {
    display: block;
  }

  &--active {
    transform: translateX(0);
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__close {
    position: relative;
    width: 24px;
    height: 24px;
    background-color: transparent;
    cursor: pointer;

    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 12px;
      height: 1px;
      background: #272727;
      top: 50%;
      left: 50%;
    }

    &:before {
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    &:after {
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }

  &__devider {
    border: 1px solid #E0E0E0;
    margin: 20px 0;
  }
}