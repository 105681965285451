@import "base/variables";
@import "base/fonts";
@import "base/normalize";

body {
  //overflow: hidden;
}

.hidden {
  display: none;
  opacity: 0;
  width: 0;
  height: 0;
}

.container {
  max-width: 1280px;
  width: 100%;
  padding: 0 150px;
  margin: 0 auto;

  &--logged-in {
    padding: 0 70px;
    max-width: 1440px;
  }

  @media (max-width: 1200px) {
    padding: 0 70px;
  }

  @media (max-width: 991px) {
    padding: 0 30px;
  }
}

.e-title {
  font: 36px/1.4 'e-Ukraine', sans-serif;
  font-weight: 500;
  color: #333333;

  @media (max-width: 991px) {
    font-size: 34px;
  }

  @media (max-width: 769px) {
    font-size: 32px;
  }

  &--white {
    color: #fff;
  }
}

.e-text {
  font: 16px/1.4 'e-Ukraine', sans-serif;
  color: #000000;

  &--white {
    color: #F2F2F2;
  }
}

@import "layout/e-header";
@import "layout/mobile-menu";
@import "layout/hero";
@import "layout/partners";
@import "layout/how-it-works";
@import "layout/how-to-inform";
@import "layout/help-to-inform";
@import "layout/qa";
@import "layout/contact-us";
@import "layout/sua";
@import "layout/footer";
@import "layout/forms";

@import "components/e-link";
@import "components/btn";
@import "components/e-message";
@import "components/info-icon";
@import "components/e-table";

@import "pages/e-page";
@import "pages/list-invites";

