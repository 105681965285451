.e-page {
  position: relative;
  overflow: hidden;
  min-height: calc(100vh - 70px);
  padding-top: 150px;
  display: flex;
  flex-direction: column;

  &__logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;

    img {
      margin-bottom: 14px;
    }
  }

  &__logo-text {
    font: 40px/1.4 'e-Ukraine', sans-serif;
    font-weight: 500;
  }

  &__title {
    font: 36px/1.4 'e-Ukraine', sans-serif;
    margin-bottom: 24px;

    @media (max-width: 576px) {
      font-size: 24px;
      margin-bottom: 20px;
    }
  }

  &__text {
    font: 18px/1.2 'e-Ukraine', sans-serif;
    margin-bottom: 32px;

    @media (max-width: 576px) {
      font-size: 16px;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;

    &--small {
      max-width: 950px;
    }

    &--wide {
      align-items: flex-start;
      justify-content: initial;
    }
  }

  &--centered {
    align-items: center;
    justify-content: center;
    text-align: center;

    .e-page__container {
      align-items: center;
      justify-content: center;
    }

    .btn {
      max-width: 335px;
      width: 100%;
      margin-bottom: 32px;
    }
  }

  &--circle-after {
    &:after {
      content: '';
      position: absolute;
      right: -130px;
      bottom: -400px;
      width: 281px;
      height: 625px;
      background: rgba(6, 33, 172, 0.1);
      filter: blur(200px);
    }
  }
}
