.help-to-inform {
  padding: 40px 0;

  .e-title {
    margin-bottom: 24px;
  }

  .e-text {
    margin-bottom: 32px;
  }

  &__container {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 70px;

    @media (max-width: 991px) {
      grid-column-gap: 50px;
    }

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      text-align: center;
    }

    &:before {
      content: "";
      position: absolute;
      left: -100px;
      bottom: -200px;
      width: 200px;
      height: 450px;
      background: rgba(0, 126, 255, 0.1);
      filter: blur(200px);
    }
  }

  .btn {
    min-width: 278px;
  }

  &__img {
    @media (max-width: 768px) {
      order: -1;
      margin-bottom: 60px;
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }
}