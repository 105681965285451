.partners {
  padding: 35px 0;

  &__list {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 185px 150px 174px;
    grid-column-gap: 65px;

    @media (max-width: 991px) {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 40px;
      grid-row-gap: 30px;
    }

    @media (max-width: 576px) {
      display: flex;
      flex-direction: column;
    }
  }

  &__item {
    font: 13px/1.4 'e-Ukraine', sans-serif;
    font-weight: 400;

    a {
      display: block;
    }

    @media (max-width: 991px) {
      text-align: center;
    }

    &--description {
      display: grid;
      align-items: center;
      grid-template-columns: 50px 1fr;
      grid-column-gap: 18px;

      @media (max-width: 991px) {
        justify-content: center;
      }

      @media (max-width: 576px) {
        span {
          max-width: 240px;
        }
      }
    }
  }
}
