.invite-page {
  position: relative;
  overflow: hidden;
  min-height: calc(100vh - 70px);
  padding-top: 170px;
  padding-bottom: 70px;

  .e-title,
  .e-text {
    text-align: center;
  }

  &__form {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }

  &__form-input {
    position: relative;
    margin-right: 15px;

    input[type=number] {
      -webkit-appearance: none;
      -moz-appearance: textfield;
    }
  }

  &__toggle-links {
    .show {
      display: inline-block;
    }

    .hide {
      display: none;
    }

    &--active {
      .show {
        display: none;
      }

      .hide {
        display: inline-block;
      }
    }
  }
}

.invite-url-td {
  display: none;

  &.active {
    display: block;
  }
}